import React from 'react';
import { useState } from 'react';
import { Alert, Button, Card, Col, Container, FloatingLabel, Form, Image, Row, Spinner } from 'react-bootstrap';
import { Bullseye, CheckCircle, EnvelopeAt, ExclamationTriangle, LightbulbFill, Mailbox, Phone } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export const Home = () => {

  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [isLoading, setIsLoading] = useState();
  const [messageSended, setMessageSended] = useState()

  const onSubmit = (data) => {
    setIsLoading(true)
    axios.post("/api/contactform",
      data
    ).then((r) => {
      reset()
      console.log(r)
      setIsLoading(false)
      setMessageSended(true)
    }).catch(() => {
      setIsLoading(false)
    })

  }

  return (
    <>
      <div className='head-block'>
        <div className='head-mask'></div>
        <Container fluid>
          <Row className="justify-content-around">
            <Col xs={12} md={6}>
              <div style={{ color: "#fff" }} className='text-md-end text-center fs-1 head-text'>
                Revolutionizing <br /> Cardiopulmonary <br /> Resuscitation and Targeted <br /> Temperature Management
                <p className='mt-3'>
                  <Button className='btn-lev fs-3 ps-5 pe-5 rounded-pill' onClick={() => window.scrollTo(0, 300)} variant=''>Read more</Button>
                </p>
              </div>
              <div>
              </div>
            </Col>
            <Col xs={6} className='d-none d-md-block'>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='border-bottom shadow mt-3 '>
        <Container className='mb-3'>
          <Row className='justify-content-center'>
            <Col lg={4}>
              <Card>
                <Card.Body>
                  <Image src='/img/shushunov.jpg' fluid className='shadow-lg pt-3' />
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4}>
              <h2 className='mt-3 mb-1' style={{ fontWeight: "600" }}>LEVMedical</h2>
              <p>
                Dr. Sergey Shushunov founded Lev Medical to create advanced cardiopulmonary resuscitation (CPR) and post-cardiac arrest care devices. With over 25 years as a critical care physician, Dr. Shushunov identified a crucial flaw in current CPR and post-resuscitation therapeutic hypothermia methods.
              </p>
              <p>
                Recognizing the limited effectiveness of current approaches, especially for the less than 10% of individuals experiencing cardiac arrest outside the hospital, Dr. Shushunov determined that the underlying physiological principles in existing CPR and therapeutic hypothermia equipment were hindering progress.
              </p>
              <p>
                Lev Medical is actively developing new resuscitation and therapeutic hypothermia devices with the goal of redefining the CPR procedure and post-resuscitation care. The aim is to significantly improve outcomes for individuals facing cardiac emergencies.
              </p>
            </Col>
          </Row>
          <h3 className='text-center mt-3' style={{ fontWeight: "600" }}>Scientific Advisory Board</h3>
          <Row className='justify-content-around'>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Image src='/img/alexanderlevitov.jpg' fluid />
                  <p className='mt-2 text-center fw-bold'>Alexander B. Levitov MD, <br /> FCCM, FCCP, RDCS.</p>
                  <p className='text-center'>
                    Professor of Pulmonary & Critical Care Medicine at Eastern  Virginia University
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Image src='/img/raulgazmuri.jpg' fluid />
                  <p className='mt-2 text-center fw-bold'>Raul Gazmuri, <br /> FCCM, FCCP, RDCS.</p>
                  <p className='text-center'>
                    Professor of Pulmonary & Critical Care Medicine at Eastern  Virginia University
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className='pt-5 d-none d-md-block'>
        <Row className='justify-content-center pt-5 pb-5'>
          <Col md={6} className='mb-3'>
            <h2 className='text-center mb-5 mt-md-5' style={{ fontWeight: 600 }}>Synchronized High Pressure Biphasic Ventilation (SHPBV): Revolutionizing CPR</h2>
            <div className='text-center'>
              <Button className='btn-lev fs-3 ps-5 pe-5 rounded-pill' onClick={() => { window.scrollTo(0, 0); navigate('/shpbv') }} variant=''>Read more</Button>
            </div>
          </Col>
          <Col md={6} lg={3} className='text-center text-md-end'>
            <Image src='/img/shpbv.jpg' fluid />
          </Col>
        </Row>
        <Row className='justify-content-center pt-5 pb-5'>
          <Col md={6} lg={3} className='text-center text-md-end'>
            <Image src='/img/oximetry2.jpg' fluid />
          </Col>
          <Col md={6} className='mb-3'>
            <h2 className='text-center mb-5 mt-md-5' style={{ fontWeight: 600 }}>Hypercold Air Mechanical Ventilation Targeted Temperature Management (TTM) Systems: Improving outcomes for stroke and cardiac arrest.</h2>
            <div className='text-center'>
              <Button className='btn-lev fs-3 ps-5 pe-5 rounded-pill' onClick={() => { navigate('/ttm'); window.scrollTo(0, 0) }} variant=''>Read more</Button>
            </div>
          </Col>

        </Row>
      </Container>
      <Container className='d-md-none mt-3'>
        <Card className='shadow mb-3'>
          <Card.Body>
            <Image src='/img/shpbv.jpg' fluid />
            <h2 className='text-center mb-5 mt-5' style={{ fontWeight: 600 }}>Synchronized High Pressure Biphasic Ventilation (SHPBV): Revolutionizing CPR</h2>
            <div className='text-center'>
              <Button className='btn-lev fs-3 ps-5 pe-5 rounded-pill' onClick={() => { window.scrollTo(0, 0); navigate('/shpbv'); window.scrollTo(0, 0) }} variant=''>Read more</Button>
            </div>
          </Card.Body>
        </Card>
        <Card className='shadow mb-3'>
          <Card.Body>
            <Image src='/img/oximetry2.jpg' fluid />
            <h2 className='text-center mb-5 mt-5' style={{ fontWeight: 600 }}>Hypercold Air Mechanical Ventilation Targeted Temperature Management (TTM) Systems: Improving outcomes for stroke and cardiac arrest.</h2>
            <div className='text-center'>
              <Button className='btn-lev fs-3 ps-5 pe-5 rounded-pill' onClick={() => {
                window.scrollTo(0, 0); navigate('/ttm'); window.scrollTo(0, 0)
              }} variant=''>Read more</Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
      <Container className='mb-3'>
        <Row>
          <Col md={6}>
            <Card className='shadow'>
              {!isLoading ?
                <Card.Body>
                  {messageSended &&
                    <Alert variant={'success'} >
                      <div style={{ inset: 0, margin: 'auto' }}>
                        <CheckCircle size={22} /> Message is sent
                      </div>
                    </Alert>}
                  <h4>Contact form</h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FloatingLabel
                      controlId="Email"
                      label="Email address"
                      className="mb-1"
                    >
                      <Form.Control type="email" placeholder="name@example.com" isInvalid={errors.email} {...register("email", { required: true, maxLength: 100, pattern: /^\S+@\S+$/i })} />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="Name"
                      label="Name"
                      className="mb-1"
                    >
                      <Form.Control type="text" placeholder="Name" isInvalid={errors.name} {...register("name", { required: true })} />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="phoneNumber"
                      label="Phone number"
                      className="mb-1"
                    >
                      <Form.Control type="tel" placeholder="Phone number"  {...register("phoneNumber")} />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="Message"
                      label="Message"
                      className="mb-1">
                      <Form.Control as="textarea" type="text" placeholder="Message"  {...register("comment")} />
                    </FloatingLabel>
                    <Button className='w-100 btn-lev' type='submit'>Send</Button>
                  </form>
                </Card.Body>
                :
                <Card.Body className='text-center fs-3 pt-3' style={{ inset: 0, margin: 'auto', height: "200px" }}>
                  Sending message
                  <div style={{ inset: 0, margin: 'auto' }}>
                    <Spinner
                      variant='info'
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                      className='mt-3'
                    />
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </Card.Body>
              }
            </Card>
          </Col>
          <Col>
            <Card className='shadow'>
              <Card.Body>
                <h4 className='mb-2'>Contact us</h4>
                <div className='mb-3'>
                  <a className='text-decoration-none' href='tel: +1 (847) 962-1858'><Phone size={24} className='me-3' />+1 (847) 962-1858</a>
                </div>
                <div className='mb-3'>
                  <a className='text-decoration-none' href='mailto:sergeyshush@domain.com'><EnvelopeAt size={24} className='me-3' />Sergeyshush@domain.com</a>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

    </>
  );
}
