
import { Col, Container, Row } from 'react-bootstrap';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const BarChartTtm = () => {
    const rangeData = [
        {
            "title": "Japan",
            "vehicles": 20000
        },
        {
            "title": "US/Canada",
            "vehicles": 55000
        },
        {
            "title": "UE",
            "vehicles": 110000
        },

        {
            "title": "Total",
            "Total vehicles": 185000
        },
    ];
    const rangeData2 = [
        {
            "title": "US/Canada",
            "vehicles": 14000
        },
        {
            "title": "Japan",
            "vehicles": 16000
        },
        {
            "title": "UE",
            "vehicles": 22000
        },
        {
            "title": "Total",
            "Total vehicles": 52000
        },
    ];
    return (

        <>
            <Container className='mt-3'>
                <Row className="justify-content-center">
                    <Col lg={6} xl={6}>
                        <b className="fs-5">Ambulances</b>
                        <p >Assumes one unit per ambulance</p>
                        <ul>
                            <li>
                                55,000 ambulance vehicles in the US and Canada
                            </li>
                            <li>
                                110,000 in the EU
                            </li>
                            <li>
                                20,000 in Japan
                            </li>
                            <li>
                                <strong>Total of 185 000 units</strong>
                            </li>
                        </ul>
                        <div style={{ width: "100%", height: 300 }}>
                            <ResponsiveContainer>
                                <BarChart width={750} height={250} data={rangeData} margin={{ top: 20, right: 20, bottom: 20, left: 20 }} >
                                    <XAxis dataKey="title" />
                                    <YAxis label={{ value: 'Vehicles', angle: -90, position: 'insideLeft', offset: -10 }} dataKey="Total vehicles" />
                                    <Tooltip />
                                    <Bar dataKey="vehicles" stackId="a" fill="#198754" />
                                    <Bar dataKey="Total vehicles" stackId="a" fill="#0d6efd" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col lg={6} xl={6}>
                        <b className="fs-5">Hospitals</b>
                        <p>Assumes two units per hospital</p>
                        <ul>
                            <li>
                                7,000 hospitals in the US and Canada
                            </li>
                            <li>
                                11,000 hospitals in EU
                            </li>
                            <li>
                                8,000 hospitals in Japan
                            </li>
                            <li>
                                <strong>Total of 52 000 units</strong>
                            </li>
                        </ul>
                        <div style={{ width: "100%", height: 300 }}>
                            <ResponsiveContainer>
                                <BarChart width={750} height={250} data={rangeData2} margin={{ top: 20, right: 20, bottom: 20, left: 20 }} >
                                    <XAxis dataKey="title" />
                                    <YAxis label={{ value: 'Vehicles', angle: -90, position: 'insideLeft', offset: -10 }} dataKey="Total vehicles" />
                                    <Tooltip />
                                    <Bar dataKey="vehicles" stackId="a" fill="#198754" />
                                    <Bar dataKey="Total vehicles" stackId="a" fill="#0d6efd" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}