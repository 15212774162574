import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './custom.css';
import { Home } from './components/Home';
import { Layout } from './components/Layout'
import { Shpbv } from './components/Shpbv';
import { Hypercold } from './components/Hypercold';

export const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />} >
        <Route index element={<Home />} />
        <Route path='/shpbv' element={<Shpbv />} />
        <Route path='/ttm' element={<Hypercold />} />
      </Route>
    </Routes>
  );
}

export default App;
