import { Button, Card, Col, Container, Image, Row } from "react-bootstrap"
import { Bullseye, ExclamationTriangle, LightbulbFill } from "react-bootstrap-icons"
import { BarChartShpbv } from "./BarChartShpbv"

export const Shpbv = () => {


    return (
        <>
            <div className='head-block'>
                <div style={{ backgroundImage: "url('/img/SHPBV2.jpg')" }} className='head-mask'></div>
                <Container>
                    <Row className="justify-content-around">
                        <Col xs={12} md={6}>
                            <div style={{ color: "#fff" }} className='text-md-end text-center fs-1 head-text'>
                                Synchronized High Pressure <br/> Biphasic Ventilation: <br/>Revolutionizing CPR
                                {/* <p className='mt-3'>
                                    <Button className='btn-lev fs-3 ps-5 pe-5 rounded-pill' onClick={() => window.scrollTo(0, 300)} variant=''>Read more</Button>
                                </p> */}
                            </div>
                            <div>
                            </div>
                        </Col>
                        <Col xs={6} className='d-none d-md-block'>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <h3 className='text-center mt-3' style={{ fontWeight: 600 }}>Problem Statement</h3>
                <Container className='mt-3 mb-3 pb-5'>
                    <Row className='g-3 justify-content-center'>
                        <Col lg={4}>
                            <Card className='shadow'>
                                <Card.Header className='text-center'>
                                    <Bullseye size={60} />
                                </Card.Header>
                                <Card.Body>
                                    <ul>
                                        <li>
                                            More than 3 million people suffer from cardiac arrest every year worldwide.
                                        </li>
                                        <li>
                                            Chest compression CPR, which was introduced in early 1960’s, is inefficient at producing blood flow higher than 25-30% of normal.
                                        </li>
                                        <li>
                                            Between 30% and 100% of people receiving traditional CPR have complications
                                        </li>
                                        <li>
                                            As a result, less than 10% of people survive out of hospital cardiac arrest events, with many survivors enduring permanent brain damage.
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className='shadow'>
                                <Card.Header className='text-center'>
                                    <ExclamationTriangle fill='red' size={60} />
                                </Card.Header>
                                <Card.Body>
                                    <ul>
                                        <li>
                                            After 50 years of research, the introduction of different modifications have failed to make measurable improvements.
                                        </li>
                                        <li>
                                            Current available mechanical CPR machines and modifications tend to increase the rate of complications in comparison with non-modified CPR without improving outcomes.
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className='shadow'>
                                <Card.Header className='text-center'>
                                    <LightbulbFill fill='#efdca0' size={60} />
                                </Card.Header>
                                <Card.Body>
                                    <ul>
                                        <li>
                                            We propose a new method of CPR, called Synchronized Biphasic High Pressure Ventilation™️ (SBHPV)1, where breaths are administered in synchrony with chest compressions and active negative pressure exhalations are administered during chest decompressions.
                                        </li>
                                        <li>
                                            In our method, chest compressions act mostly as a way to restrict chest wall expansion to prevent lung hyperinflation and damage caused by high pressure breaths.
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <div className='border-bottom border-top shadow mt-3 pt-3'>
                    <h3 className='text-center mt-3' style={{ fontWeight: 600 }}>Proposed Solution - How does it work?</h3>

                    <Container className='mb-3'>
                        <Row className='justify-content-center'>
                            <Col md={8}>
                                <ul>
                                    <li>
                                        The manually operated device has a ventilator build into a chest compressor. The manual pressure applied to the chest compressor simultaneously inflates the lungs.
                                        <br />
                                        <div className="text-center mt-2 mb-2">
                                            <Image src='/img/crp.jpg' fluid className='shadow-lg' />
                                        </div>
                                    </li>
                                    <li>
                                        The automatically operated device consists of two units:
                                        High pressure bi-phasic lung ventilator
                                        Pneumatic chest compression band.
                                    </li>
                                    <li>
                                        The driving force behind the blood flow is high chest cavity pressure, instead of mechanical compression of the heart during manual or mechanical CPR.
                                    </li>
                                    <li>
                                        Synchronized chest compression acts predominantly as method of limiting lung hyperinflation and injury during high pressure breath.
                                    </li>
                                </ul>
                                <div>
                                    <h6>
                                        <u>Both units work in synchrony in two phases:</u>
                                    </h6>
                                    <p>
                                        <strong>Phase 1:</strong> Ventilator delivers pressurized oxygen to the lungs during chest compression generating high pressure within  the chest cavity and forcing blood out of the lungs into aorta.
                                    </p>
                                    <p>
                                        <strong>Phase 2:</strong> Ventilator applies negative pressure to the airways, removing stale air and creating negative pressure inside the chest cavity, which increases blood flow to the lung’s  blood vessels, while chest compression relaxes, allowing lungs to expand.
                                    </p>
                                </div>
                            </Col>
                            {/* <Col md={5}>
                                <h2 className='mt-3' style={{ fontWeight: "600" }}>LEVMedical</h2>
                                <p>
                                    Subscriber Growth
                                    List growth is the foundation for any successful SMS marketing program. The larger it grows, the more value you drive. We’ve built the industry’s most comprehensive set of subscriber growth tools to maximize the size of your audience and keep it growing.
                                </p>
                            </Col> */}
                        </Row>
                    </Container>
                </div>
                <Container>
                    <h3 className='text-center mt-3' style={{ fontWeight: 600 }}>
                        Advantages of SHPBV CPR™️
                    </h3>
                    <ul>
                        <li>
                            High and consistent blood pressure and flow with a smaller depth of chest compressions will prevent injuries and a loss of chest recoil.
                        </li>
                        <li>
                            Prevention of mitral valve insufficiency caused by deep chest compressions, will preclude retrograde blood flow and CPR associated lung edema.
                        </li>
                        <li>
                            Elimination of compression-to-compression or rescuer-to-rescuer variability and the need to determine the optimal depth of chest compression.
                        </li>
                        <li>
                            Generation of negative intrathoracic pressure during active exhalation independent of chest recoil during the relaxation phase of CPR  will increase venous blood return and myocardial perfusion pressure and blood flow.
                        </li>
                        <li>
                            Generation of high intrathoracic pressure during the compression phase of CPR resulting in high arterial pressure will increase cerebral perfusion pressure and blood flow.
                        </li>
                        <li>
                            Ability to adjust ventilator lung inflation pressure based on patient physiologic response (true “patient-centric CPR”).
                        </li>
                        <li>
                            Improvement of carbon dioxide removal from the lungs with active exhalation.
                        </li>
                    </ul>
                </Container>
                <BarChartShpbv />
            </div>
        </>
    )
}