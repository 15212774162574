import { Button, Card, Col, Container, Image, Row } from "react-bootstrap"
import { Bullseye, ExclamationTriangle, LightbulbFill } from "react-bootstrap-icons"
import { BarChartShpbv } from "./BarChartShpbv"
import { BarChartTtm } from "./BarChartTtm"

export const Hypercold = () => {

    return (
        <>
            <div className='head-block'>
                <div style={{ backgroundImage: "url('/img/hospital.jpg')", backgroundPosition: "center bottom" }} className='head-mask'></div>
                <Container>
                    <Row className="justify-content-around">
                        <Col xs={12} md={6}>
                            <div style={{ color: "#fff" }} className='text-md-end text-center fs-1 head-text'>
                                Hypercold Air Mechanical<br/> Ventilation Targeted Temperature <br/> Management System
                                {/* <p className='mt-3'>
                                    <Button className='btn-lev fs-3 ps-5 pe-5 rounded-pill' onClick={() => window.scrollTo(0, 300)} variant=''>Read more</Button>
                                </p> */}
                            </div>
                            <div>
                            </div>
                        </Col>
                        <Col xs={6} className='d-none d-md-block'>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <h3 className='text-center mt-3' style={{ fontWeight: 600 }}>Problem Statement</h3>
                <Container className='mt-3 mb-3 pb-5'>

                    <Row className='g-3 justify-content-center'>
                        <Col lg={4}>
                            <Card className='shadow'>
                                <Card.Header className='text-center'>
                                    <Bullseye size={60} />
                                </Card.Header>
                                <Card.Body>
                                    <ul>
                                        <li>
                                            over 35 million people worldwide die from sudden death severe stroke, brain injury or Acute Lung Injury
                                        </li>
                                        <li>
                                            Many of them can be saved with total body cooling to about 32-34 Co if it is achieved during the first 1-2 hours following catastrophic event.
                                        </li>
                                        <li>
                                            Targeted Temperature Management (formally known as Therapeutic Hypothermia) was introduced to medical practice in early 2000s.
                                        </li>
                                        <li>
                                            Current Therapeutic Hypothermia Methods are ineffective either due to the slow rate of cooling, or impractical design, high complexity or high rate of serious side effects.
                                        </li>
                                        <li>
                                            Our new Therapeutic Hypothermia System will bring dramatic changes.
                                        </li>
                                    </ul>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className='shadow'>
                                <Card.Header className='text-center'>
                                    <ExclamationTriangle fill='red' size={60} />
                                </Card.Header>
                                <Card.Body>
                                    <ul>
                                        <li>
                                            Cooling body wraps are simple and easy to use, but they don’t provide fast rate of cooling, and limit access to a patient for diagnostic or life saving procedures.

                                        </li>
                                        <li>
                                            Imitation of ice water immersion provides fast rate of cooling, has no serious complications, but the equipment is very heavy and blocks access to a patient for diagnostic or life saving procedures.
                                        </li>
                                        <li>
                                            Venous devices percolated with ice water require specialized training, don’t provide fast rate of cooling, and have serious side effects.
                                        </li>
                                        <li>
                                            Extracorporeal cooling provides fast rate of cooling, but it can only be done in a small number of larger hospitals, requires highly trained and specialized staff, and has high rate of complications.
                                        </li>
                                        <li>
                                            Esophageal cooling device is easy to use, does not have serious complications, but does not provide fast rate of cooling.
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className='shadow'>
                                <Card.Header className='text-center'>
                                    <LightbulbFill fill='#efdca0' size={60} />
                                </Card.Header>
                                <Card.Body>
                                    <ul>
                                        <li>
                                            Our method, called Hypercold Air Mechanical Ventilation uses lungs high surface area as a heat exchanger
                                        </li>
                                        <li>
                                            Patients who stand to gain from (TTM) are already undergoing mechanical ventilation, with the only necessary adjustment being the incorporation of cooling air into the ventilation circuit for hypothermia induction.
                                        </li>
                                        <li>
                                            New TTM System will be easy to use, portable, provide a fast rate of cooling to achieve target body temperature within 1 hours after the start and lack side effects.
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <div className='border-bottom border-top shadow mt-3 pt-3'>
                    <h3 className='text-center mt-3' style={{ fontWeight: 600 }}>Proposed Solution - How does it work?</h3>

                    <Container className='mb-3'>
                        <Row className='justify-content-center'>
                            <Col md={4}>
                                <ul>
                                    <li>
                                        Our cooling device consists of a small freezer filled with a cooling media which does not solidify at subfreezing temperature (for example, 28% CaCl solution).
                                    </li>
                                    <li>
                                        The freezer has a copper coil immersed into cooling media.
                                    </li>
                                    <li>
                                        The coil is attached to a mechanical ventilator tubing on one end and to a patient’s breathing tube on another end.
                                    </li>
                                    <li>
                                        The temperature of the cooling media can be changed as needed to meet the clinical goals.
                                    </li>
                                    <li>
                                        The air runs from the mechanical ventilator through the freezer to the patient at fast flow, while the patient is ventilated at higher rate than physiologically necessary.
                                    </li>
                                    <li>
                                        The excessive removal of CO2 caused by high rate of ventilation is compensated by adding CO2 to inhaled air producing what we call Compensated Hyperventilation™️.
                                    </li>
                                </ul>
                            </Col>
                            <Col md={4}>
                                <div className="text-center mt-2 mb-2">
                                    <Image src='/img/ttm.png' fluid className='shadow-lg' />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <h3 className='text-center mt-3' style={{ fontWeight: 600 }}>
                        Advantages of SHPBV CPR™️
                    </h3>
                    <ul>
                        <li>
                            Reaches target temperature within 1 hour.
                        </li>
                        <li>
                            Minimally invasive: All patients undergoing therapeutic hypothermia receive mechanical ventilation due to the nature of their                                       condition and have an endotracheal (breathing) tube already inserted.
                        </li>
                        <li>
                            Allows for unobstructed access to the patient for diagnostic and life saving procedures.
                        </li>
                        <li>
                            Compact and portable, facilitates use throughout patient transport and minimizes equipment crowding.
                        </li>
                        <li>
                            Allows for initiation of hypothermia in prehospital settings, such as ground or air ambulances.
                        </li>
                        <li>
                            Easy to operate by minimally trained staff; only requires placing device in line with the ventilator tubing.
                        </li>
                        <li>
                            Suitable for induction of hypothermia in patients with Acute Lung Injury by providing a direct pulmonary cooling effect.
                        </li>
                    </ul>
                </Container>
                <BarChartTtm />
            </div>
        </>
    )
}