import { useEffect, useState } from 'react';
import './NavMenu.css';
import { Container, Image, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Linkedin } from 'react-bootstrap-icons';

export const NavMenu = () => {

  const [bgNav, setBgNav] = useState();
  const [show, setShow] = useState(false);
  const navigete = useNavigate();
  const loaction = useLocation();

  useEffect(() => {
    const scrollHandeler = (e) => {
      if (e.target.documentElement.scrollTop > 15) {
        setBgNav("bg-body-tertiary")
        // setLogoColot()
      }
      else {
        setBgNav('navbar-dark')
        // setLogoColot()
      }
    }
    document.addEventListener('scroll', scrollHandeler)
    return function () {
      document.removeEventListener('scroll', scrollHandeler)
    }
  }, [loaction])

  useEffect(() => {
    if (show === true) {
      setBgNav("bg-body-tertiary")
      // setLogoColot()
    }
    else {
      setBgNav('navbar-dark')
      // setLogoColot()
    }
  }, [show])

  const toNav = (link) => {
    window.scrollTo(0, 0)
    navigete(link)
    if (window.innerWidth < 768) {
      setShow(false)
    }
  }

  return (
    <Navbar fixed={"top"} expand="lg" className={`justify-content-center nav-transition navbar-lev shadow ${bgNav} `} >
      <Container fluid className=''>
        <Navbar.Brand onClick={() => { toNav('/') }}>
          <Image src='/img/logo.png' className='mt-1 mb-1' style={{ maxHeight: '55px' }} fluid alt='logo-lev' />
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => { setShow(!show) }} aria-controls={`offcanvasNavbar-expand-lg`} />
        <Navbar.Offcanvas
          className="offcanvas-nav-lc"
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
          show={show}
          onHide={() => { setShow(false) }}
        >
          <Offcanvas.Header closeButton closeVariant='white'>
            <Offcanvas.Title className='d-flex w-100 justify-content-around' id={`offcanvasNavbarLabel-expand-lg`}>
              <Image src='/img/logo.png' style={{ maxHeight: '55px' }} fluid alt='logo-lev' />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="ms-auto me-auto justify-content-center text-center">
              <Nav.Link onClick={() => {
                toNav("/")
              }}>Home</Nav.Link>
              <Nav.Link onClick={() => {
                toNav("/")
                window.scrollTo(0, 400)
              }}>Concept</Nav.Link>
              <NavDropdown title='Solution'>
                <NavDropdown.Item onClick={() => { toNav("/shpbv") }}>SHPBV</NavDropdown.Item>
                <NavDropdown.Item onClick={() => { toNav("/ttm") }}>TTM</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link onClick={() => {
                toNav("/")
              }}>Contact</Nav.Link>
            </Nav>
            <div className='justify-content-between pt-2'>
              {/* <Twitter className='me-3 ms-3' size={16} style={{ cursor: "pointer" }} /> */}
              {/* <Facebook className='me-3 ms-3' size={16} style={{ cursor: "pointer" }} /> */}
              <Linkedin className='me-3 ms-3' onClick={() => { window.open("https://www.linkedin.com/company/3807220/admin/feed/posts/", "_blank") }} size={16} style={{ cursor: "pointer" }} />
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
