import { Col, Container, Image, Row } from "react-bootstrap"


export const Footer = () => {


    return (
        <footer className="footer">
            <Container>
                <Row className="justify-content-around">
                    <Col xs={3}>
                        <div >
                            <Image alt="LEV Medical logo white" src="/img/logo-white.png" fluid style={{ height: "100px" }} />
                        </div>
                    </Col>
                    <Col xs={'auto'}>

                    </Col>
                    <Col xs={'auto'}>
                    </Col>
                    <Col xs={'auto'}>
                    </Col>
                </Row>

            </Container>
            <div className='text-center'>
                <div className='fs-5 text-white'>LEV MEDICAL</div>
                <div className='text-white'>TTM Inc DBA</div>
            </div>
        </footer>
    )
}